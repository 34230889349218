// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-theme-blog-src-templates-post-js": () => import("/home/runner/work/jb-san.github.io/jb-san.github.io/node_modules/gatsby-theme-blog/src/templates/post.js" /* webpackChunkName: "component---node-modules-gatsby-theme-blog-src-templates-post-js" */),
  "component---node-modules-gatsby-theme-blog-src-templates-posts-js": () => import("/home/runner/work/jb-san.github.io/jb-san.github.io/node_modules/gatsby-theme-blog/src/templates/posts.js" /* webpackChunkName: "component---node-modules-gatsby-theme-blog-src-templates-posts-js" */),
  "component---src-pages-about-js": () => import("/home/runner/work/jb-san.github.io/jb-san.github.io/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-projects-index-js": () => import("/home/runner/work/jb-san.github.io/jb-san.github.io/src/pages/projects/index.js" /* webpackChunkName: "component---src-pages-projects-index-js" */),
  "component---src-pages-projects-reactstorm-components-box-index-js": () => import("/home/runner/work/jb-san.github.io/jb-san.github.io/src/pages/projects/reactstorm/components/Box/index.js" /* webpackChunkName: "component---src-pages-projects-reactstorm-components-box-index-js" */),
  "component---src-pages-projects-reactstorm-components-box-leftshim-js": () => import("/home/runner/work/jb-san.github.io/jb-san.github.io/src/pages/projects/reactstorm/components/Box/Leftshim.js" /* webpackChunkName: "component---src-pages-projects-reactstorm-components-box-leftshim-js" */),
  "component---src-pages-projects-reactstorm-components-contentbox-index-js": () => import("/home/runner/work/jb-san.github.io/jb-san.github.io/src/pages/projects/reactstorm/components/Contentbox/index.js" /* webpackChunkName: "component---src-pages-projects-reactstorm-components-contentbox-index-js" */),
  "component---src-pages-projects-reactstorm-components-link-index-js": () => import("/home/runner/work/jb-san.github.io/jb-san.github.io/src/pages/projects/reactstorm/components/Link/index.js" /* webpackChunkName: "component---src-pages-projects-reactstorm-components-link-index-js" */),
  "component---src-pages-projects-reactstorm-components-livebox-index-js": () => import("/home/runner/work/jb-san.github.io/jb-san.github.io/src/pages/projects/reactstorm/components/Livebox/index.js" /* webpackChunkName: "component---src-pages-projects-reactstorm-components-livebox-index-js" */),
  "component---src-pages-projects-reactstorm-components-logo-index-js": () => import("/home/runner/work/jb-san.github.io/jb-san.github.io/src/pages/projects/reactstorm/components/Logo/index.js" /* webpackChunkName: "component---src-pages-projects-reactstorm-components-logo-index-js" */),
  "component---src-pages-projects-reactstorm-components-page-index-js": () => import("/home/runner/work/jb-san.github.io/jb-san.github.io/src/pages/projects/reactstorm/components/Page/index.js" /* webpackChunkName: "component---src-pages-projects-reactstorm-components-page-index-js" */),
  "component---src-pages-projects-reactstorm-components-pixeltext-index-js": () => import("/home/runner/work/jb-san.github.io/jb-san.github.io/src/pages/projects/reactstorm/components/Pixeltext/index.js" /* webpackChunkName: "component---src-pages-projects-reactstorm-components-pixeltext-index-js" */),
  "component---src-pages-projects-reactstorm-components-quicklink-index-js": () => import("/home/runner/work/jb-san.github.io/jb-san.github.io/src/pages/projects/reactstorm/components/Quicklink/index.js" /* webpackChunkName: "component---src-pages-projects-reactstorm-components-quicklink-index-js" */),
  "component---src-pages-projects-reactstorm-components-quicklink-separator-js": () => import("/home/runner/work/jb-san.github.io/jb-san.github.io/src/pages/projects/reactstorm/components/Quicklink/Separator.js" /* webpackChunkName: "component---src-pages-projects-reactstorm-components-quicklink-separator-js" */),
  "component---src-pages-projects-reactstorm-components-snow-index-js": () => import("/home/runner/work/jb-san.github.io/jb-san.github.io/src/pages/projects/reactstorm/components/Snow/index.js" /* webpackChunkName: "component---src-pages-projects-reactstorm-components-snow-index-js" */),
  "component---src-pages-projects-reactstorm-compositions-livebar-js": () => import("/home/runner/work/jb-san.github.io/jb-san.github.io/src/pages/projects/reactstorm/compositions/Livebar.js" /* webpackChunkName: "component---src-pages-projects-reactstorm-compositions-livebar-js" */),
  "component---src-pages-projects-reactstorm-compositions-navbar-js": () => import("/home/runner/work/jb-san.github.io/jb-san.github.io/src/pages/projects/reactstorm/compositions/Navbar.js" /* webpackChunkName: "component---src-pages-projects-reactstorm-compositions-navbar-js" */),
  "component---src-pages-projects-reactstorm-index-js": () => import("/home/runner/work/jb-san.github.io/jb-san.github.io/src/pages/projects/reactstorm/index.js" /* webpackChunkName: "component---src-pages-projects-reactstorm-index-js" */)
}

